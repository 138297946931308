import * as React from 'react';
import { 
  Body,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Section,
  Text 
} from '@react-email/components';
import { Tailwind } from "@react-email/tailwind";

export function Email({name, fromAddress, bodyText}) {

  return (
    <Html>
      <Head />
      <Tailwind>
        <Body className="bg-white my-auto mx-auto font-sans px-2">
          <Container className="border border-solid border-[#eaeaea] rounded my-[40px] mx-auto p-[20px] max-w-[465px]">
            <Section className="mt-[32px]">
              <Img
                src="https://nicholashunt.io/goat.png"
                width="120"
                alt="goat"
                className="my-0 mx-auto"
              />
            </Section>
            <Text className="m-0 text-black font-bold text-[16px] leading-[24px]">
              New Email!
            </Text>
            <Text className="m-0 pl-[1rem] text-black text-[12px] leading-[24px]">
              Name: {name}
            </Text>
            <Text className="m-0 pl-[1rem] text-black text-[12px] leading-[24px]">
              Email: &lt;{fromAddress}&gt;
            </Text>
            <Hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />
            <Text className="m-0 text-black font-bold text-[16px] leading-[24px]">They said -</Text>
            <Text className="m-0 pl-[1rem] text-black text-sm leading-[24px]">{bodyText}</Text>
            <Hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />
            <Text className="text-[#666666] text-[12px] leading-[24px]">
              From nicholashunt.io
            </Text>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default Email;