import React, { useState, useRef } from "react";
import '../styles/desktop.css'


function Desktop() {

  return (
    <div className="static-desktop">
      <div className="static-game-row-1 static-game-row">
            <div className="static-game-col-1-1 static-game-col noselect">

            </div>
            <div className="static-game-col-1-2 static-game-col noselect">

            </div>
        </div>
        <div className="static-game-row-2 static-row">
            <div className="static-game-col-2-1 static-game-col noselect">

            </div>
        </div>
    </div>
  );
}

export default Desktop;
