import React from "react";
import Studies from './Studies/Studies'
import Projects from './Projects/Projects'
import Interests from './Interests/Interests'
import Contact from './Contact Me/Contact'
import Experience from './Experience/Experience'
import Skills from './Skills/Skills'
import '../../../styles/Popups/popup.css'


function PopUp({type, x, removePopUp}) {
    let style = {}
    let screenWidth = window.innerWidth
    if (type === 'sTan' || type === 'p') {
        x = 0
    }
    style = {
        "left": "calc(5% + " + x + "px)"
    }
    
    return (
        <div id={"PopUpParent"} style={style}>
            {type === 'sTan' && <Studies removePopUp={removePopUp}/>}
            {type === 'p' && <Projects removePopUp={removePopUp}/>}
            {type === 'i' && <Interests removePopUp={removePopUp}/>}
            {type === 'c' && <Contact removePopUp={removePopUp}/>}
            {type === 'e' && <Experience removePopUp={removePopUp}/>}
            {type === 'sOrange' && <Skills removePopUp={removePopUp}/>}
        </div>
    );
}

export default PopUp;