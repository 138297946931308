import React from "react";
import { Worker, Viewer, SpecialZoomLevel  } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import resume from "../../../assets/resume/Nicholas_Hunt_Resume.pdf"
import "../styles/resume.css"

const Resume = ({isMobile}) => {
  
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      <div style={{"display":"flex", "flex-direction": "column", "align-items": "center", "justify-content": "center"}}>
        <div className="colored-border">
          <div className="resume-pdf-container"> 
            <Viewer 
              fileUrl={resume} 
              defaultScale={isMobile ? SpecialZoomLevel.PageWidth : SpecialZoomLevel.PageFit} 
              plugins={[getFilePluginInstance]}
            />
            {
              !isMobile &&
              <Download>
                {
                  (props) => (
                    <div onClick={props.onClick} className={"static-button resume-static-button"}>
                      <div className="static-button-container resume-static-button-container ">
                        <p className="noselect">Download</p>
                      </div>
                  </div>
                  )
                }
              </Download>
            }
          </div>
        </div>
      </div>
    </Worker>
  )
}


export default Resume