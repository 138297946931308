import React, {useState, useEffect, useReducer, useRef} from "react";
import {animateRight, animateLeft, jump, platformCollision, gravity, bookCollision, keyCollision} from '../../../helper/playerHelper'
import DPad from '../Components/DPad'
import {initState, reducer} from './State'
import Location from '../Components/Location'
import OpenBook from '../Components/Prompt'
import PopUp from '../Popups/PopUp'
import FlatBooks from '../Components/FlatBooks'
import initOutsidePlatforms from '../Outside/platform.json'
import '../../../styles/Index/index.css'
import { useCookies } from 'react-cookie';


let initBooksFound = {
  "sTan": false,
  "p": false,
  "i": false,
  "c": false,
  "e": false,
  "sOrange": false,
  "basementKey": false
}

function Index() {
  // Establish Cookies
  const [cookies, setCookie, removeCookie] = useCookies(['books']);
  // Define our state
  const [state, dispatch] = useReducer(reducer, initState);
  const [iteration, setIteration] = useState(0)
  const stateRef = useRef(state)
  const JSx = useRef() 
  const keysPressedSet = new Set() 
  // eslint-disable-next-line
  const [platforms, setPlatforms] = useState(initOutsidePlatforms)
  // eslint-disable-next-line
  const [bookPositions, setBookPositions] = useState()
  const [nearBook, setNearBook] = useState(null)
  const [nearKey, setNearKey] = useState(null)
  const bookRef = useRef({book: null})
  const keyRef = useRef({key: null})
  const showPopUpRef = useRef(null)
  const [showPopUp, setShowPopUp] = useState(null)
  const [booksFound, setBooksFound] = useState(initBooksFound)
  const booksFoundRef = useRef({books: booksFound});
  const locationRef = useRef({currLocation: null, prevLocation: null})


  if (!cookies.books) {
    setCookie(initBooksFound)
  } else {
    initBooksFound = cookies.books
  }
  
  const jumpGravityRef = (isSetting, inc) => {
    if (isSetting) {
      stateRef.isJumping = true
      stateRef.gravity = true
      dispatch({type: 'setY', value: inc})
    } else {
      stateRef.isJumping = false
      stateRef.gravity = false  
    }
  }
  
  const updateWindowX = (windowValue) => {
    stateRef.currentWindowLeftX = windowValue
  }

  const onTouchStartHandler = (event) => {
    if (showPopUpRef.loc) {
      return
    }
    // Initially setting a boolean to know we are on a mobile device.
    if (state.deviceIsDesktop) {
      dispatch({type: 'switchToMobile'})
    }
    if (event.changedTouches){
      let screenWidth = window.screen.width
      let touches = event.changedTouches
      // Allowing multi-touch actions (jumping and moving)
      for (let Touch of touches) {
        let X = Touch.pageX
        let Y = Touch.pageY
        // Left side of the screen taps will render a dpad for movement
        if (X <= (screenWidth / 2) + stateRef.currentWindowLeftX) {
          dispatch({type: 'updateDPad', bool: true, x: X, y: Y})
        } 
        // Right side of the screen taps are jump
        else if (X > (screenWidth / 2) + stateRef.currentWindowLeftX 
                  && !stateRef.gravity && !stateRef.isJumping) {
          jump(dispatch, jumpGravityRef, stateRef.playerImgNum)
          setTimeout(() => {
            stateRef.isJumping = false
            stateRef.gravity = false
          }, 1130)
        } 
      }
    }
  }

  const setDirectionRef = (isRight) => {
    if (isRight) {
      stateRef.playerRight = true
      stateRef.playerLeft = false
      dispatch({type: 'setMoveRight'})
    } else {
      stateRef.playerRight = false
      stateRef.playerLeft = true
      dispatch({type: 'setMoveLeft'})
    }
  }

  const onTouchEndHandler = (event) => {
    if (showPopUpRef.loc) {
      return
    }
    dispatch({type: 'updateDPad', bool: false})
    JSx.value = 63-16 + stateRef.currentWindowLeftX
    if (stateRef.playerRight) {
      stateRef.playerRight = false
      dispatch({type: 'stopMoveRight'})
    } else if (stateRef.playerLeft) {
      stateRef.playerLeft = false
      dispatch({type: 'stopMoveLeft'})
    }
  }

  const onKeyUpHandler = (event) => {
    if (showPopUpRef.loc) {
      return
    }
    const key = event.key
    keysPressedSet.delete(key)
    // Move to the right
    if (key === 'd' || key === 'D' || key === "ArrowRight") {
      dispatch({type: 'stopMoveRight'})
      setIteration(0)
    }
    // Move to the left
    if (key === 'a' || key=== 'A' || key === "ArrowLeft") {
      dispatch({type: 'stopMoveLeft'})
      setIteration(0)
    }
}

const removeBookPosition = (identifier) => {
  let bookCopy = JSON.parse(JSON.stringify(bookPositions))
  for ( let currY in bookCopy ) {
    let currArray = bookPositions[currY]
    let idx = currArray.map( (ele) => ele.identifier).indexOf(identifier)
    if (idx !== -1) {
      currArray.splice(idx, 1)
      if (currArray.length === 0)
        delete bookCopy[currY]
      bookCopy[currY] = currArray
      break
    }
  }

  setBookPositions(bookCopy)
}

const setBookTrue = (identifier) => {
  let booksFoundCopy = JSON.parse(JSON.stringify(booksFoundRef.current.books))
  booksFoundCopy[identifier] = true
  booksFoundRef.current.books = booksFoundCopy
  setBooksFound(booksFoundCopy)
  setCookie('books', booksFoundCopy)
}

const setKeyTrue = () => {
  let booksFoundCopy = JSON.parse(JSON.stringify(booksFoundRef.current.books))
  booksFoundCopy['basementKey'] = true
  booksFoundRef.current.books = booksFoundCopy
  setBooksFound(booksFoundCopy)
  setCookie('books', booksFoundCopy)
}

const showPopUpPage = (identifier) => {
  showPopUpRef.loc = identifier 
  setShowPopUp(identifier)
}

const collectBook = (identifier) => {
  showPopUpPage(identifier)
  setNearBook(null)
  // removeBookPosition(identifier)
  setBookTrue(identifier)
}

  const onKeyDownHandler = (event) => {
    if (showPopUpRef.loc) {
      return
    }
    // Preventing scrolling from space or arrow keys
    if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(event.Code) > -1) {
      event.preventDefault();
    }
    const key = event.key
    // Move to the Right
    if (key === 'd' || key === 'D' || key === "ArrowRight") {
        dispatch({type: 'setMoveRight'})
    } 
    // Move to the Left
    if (key === 'a' || key === 'A' || key === "ArrowLeft") {
        dispatch({type: 'setMoveLeft'})
    }
    // Jump
    if (key === ' ' && !stateRef.gravity && !stateRef.isJumping && !keysPressedSet.has(key) ) {
      jump(dispatch, jumpGravityRef, stateRef.playerImgNum)
    }
    // Activate object
    if ( (key === 'e' || key === 'E') ) {
      if (bookRef.book && !booksFound[bookRef.book]) {
        collectBook(bookRef.book)
        bookRef.book = null
      } else if (locationRef.current.currLocation === 'Basement' && booksFoundRef.current.books.basementKey === false) {
        locationRef.current.currLocation = 'Outside'
      } else if (locationRef.current.currLocation !== null && locationRef.current.prevLocation === 'Outside') {
        dispatch({type: 'changeLocation', location: locationRef.current.currLocation})
      } else if (keyRef.key) {
        setKeyTrue()
        setNearKey(false)
      }
    } 
    if (!keysPressedSet.has(key) && (key === 'r' || key === 'R') && locationRef.current.prevLocation === 'Outside' ) {
      dispatch({type: 'changeLocation', location: 'Outside'})
    }
    keysPressedSet.add(key);
  }

  const removePopUp = () => {
    const width = document.getElementById(state.playerId).offsetWidth
    showPopUpRef.loc = null
    setShowPopUp(null)
    window.requestAnimationFrame(() => { 
      let book = bookCollision(state.playerX, state.playerY, width, bookPositions)
      bookRef.book = book
      setNearBook(book)

    })
  }

  useEffect( () => {
    stateRef.playerImgNum = state.playerImgNum
    if (state.playerRight) {
      // eslint-disable-next-line 
      window.requestAnimationFrame(() => animateRight(state, dispatch, updateWindowX, iteration))
      setIteration(i => i + 1)
    }
    if (state.playerLeft) {
      // eslint-disable-next-line
      window.requestAnimationFrame(() => animateLeft(state, dispatch, updateWindowX, iteration))
      setIteration(i => i + 1)
    } 
    if (state.gravity) {
      window.requestAnimationFrame(() => gravity(state, dispatch))  
    } 
    
  }, [state])

  useEffect( () => {

    const width = document.getElementById(state.playerId).offsetWidth
    if (!state.isJumping) {
      // eslint-disable-next-line
      window.requestAnimationFrame(() => platformCollision(state.playerX, state.playerY, state.isJumping, width, dispatch, platforms))
    }
    // eslint-disable-next-line
    let book = null
    window.requestAnimationFrame(() => {
      var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 
      book = bookCollision(state.playerX, state.playerY, width, bookPositions, booksFound)
      setNearBook(book)
      bookRef.book = book
      if (!booksFound.basementKey) {
        let key = keyCollision(state.playerX, state.playerY, width, 50 * ratio, 30 * ratio, 1700 * ratio, window.innerHeight * .6)
        setNearKey(key)
        keyRef.key = key
      }
    })
  }, [state.playerX, state.playerY, state.isJumping, platforms, state.gravity, bookPositions])

  useEffect( () => {
    // eslint-disable-next-line
    window.scrollTo(state.currentWindowLeftX, 0)
  }, [state.location])

  useEffect(() => {
    // eslint-disable-next-line
    window.addEventListener('touchstart', onTouchStartHandler, {passive: false})
    // eslint-disable-next-line
    window.addEventListener('touchend', onTouchEndHandler, {passive: false})
    // eslint-disable-next-line
    window.addEventListener('keydown', onKeyDownHandler, {passive: false})
    // eslint-disable-next-line
    window.addEventListener('keyup', onKeyUpHandler, {passive: false})
    window.scrollTo(0, 0)
    if (stateRef.currentWindowLeftX === undefined) {
      stateRef.currentWindowLeftX = 0   
    }
    if (JSx.value === undefined) {
      JSx.value = 0
    }
    
  }, [])  

  
  return (
    <div>
      <div className="HomeChild" tabIndex="0">
        <Location setBookPositions={setBookPositions} 
                  locType={state.location}
                  setPlatforms={setPlatforms}
                  state={state}
                  booksFound={booksFound}
                  locationRef={locationRef}/>      
        {state.dPad.bool && 
        <DPad name={"DPad"} 
              x={stateRef.currentWindowLeftX + state.dPad.x} 
              y={state.dPad.y} JSx={JSx} state={state}
              setDirectionRef={setDirectionRef} 
        />} 
        <FlatBooks x={state.currentWindowLeftX} booksFound={booksFound} showPopUpPage={showPopUpPage}/>
        {nearBook && !showPopUpRef.loc && !showPopUp &&
          <OpenBook x={state.currentWindowLeftX} displayText="Press E to Open Book"/>
        }
        {nearKey && 
          <OpenBook x={state.currentWindowLeftX} displayText="Press E to grab the key"/>
        }
        {showPopUpRef.loc && showPopUp && 
          <PopUp type={showPopUp} x={state.currentWindowLeftX} removePopUp={removePopUp}/>
        }
      </div>
    </div>
  );
}

export default Index;
