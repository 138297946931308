

export const email = async (email_body) => {
  return await fetch('https://api.nicholashunt.io/email', {
    method: 'POST',
    body: JSON.stringify(email_body), // string or object
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });
}