import React from "react";
import wood from '../../../assets/structures/Wood.png'
import '../../../styles/Misc/platforms.css'


function getImageSource(imgType) {
    switch (imgType) {
        case 'wood':
            return wood
    }
}

function Platform({name, imgType, class_name, x, y}) {
    
    let imageSource = getImageSource(imgType) 


    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 
    let style = { 
        bottom: y * ratio,
        left: x * ratio
    }

    return (
        <img id={name} className={class_name} src={imageSource} style={style} alt=""></img>
    );
}

export default Platform;