import React from "react";
import '../styles/year.css'

function Year({SubHeading, Year, Months, totalYears}) {
    let monthComponents = []
    let isFirst = Year === "2019"
   
    return (
        <div className="static-year" style={{"flex": `${1/totalYears} 0`}}>
            <div className={isFirst ? "static-year-first static-year-context" : "static-year-context"}>
                <p className="static-year-year">{Year}</p>
                {
                    SubHeading !== "" && 
                    <p className="static-year-subheader">{SubHeading}</p>
                }
            </div>
            {isFirst && <div className="static-year-vertical"></div>}
            <div className="static-year-horizontal"></div>
            <div className="static-year-vertical"></div>
        </div>
    );
}

export default Year;
