import React, {useEffect} from "react";
import DPadBackground from '../../../assets/Misc/DPad_Background.png'
import DPadJoyStick from '../../../assets/Misc/DPad_Joystick.png'
import '../../../styles/Misc/dpad.css'

function DPad({name, x, y, JSx, setDirectionRef, state}) {

    let screenHeight = document.body.clientHeight

    let DPadX = x - 63

    let bottom = screenHeight - y
    let style = {
        position: "absolute",
        left: DPadX,
        bottom: bottom,
        height: 46,
        width: 126
    }

    let JSStyle = {
        left: JSx.value,
        bottom: 0
    }

    const onTouchMoveHandler = (event) => {
        let middleJoyStick = 63 - 16
        let middleDPad = x

        let Touch = event.changedTouches[0]
        let X = Touch.pageX
        let diff = X - middleDPad
        //To the right
        let newValue = JSx.value
        if (diff > 0 && !state.moveRight) {
            setDirectionRef(true)
        } else if (diff <=0 && !state.moveLeft) {
            setDirectionRef(false)
        }
        if (middleJoyStick + diff <= 94 && middleJoyStick + (diff) >= 0) {
            newValue = middleJoyStick + diff
        } else if (middleJoyStick + diff > 94) {
            newValue = 94
        } else if (middleJoyStick + diff < 0) {
            newValue = 0
        }
        JSx.value = newValue

        event.preventDefault();
    }
    window.addEventListener('touchmove', onTouchMoveHandler, {passive:false})


    return (
        <div id={name} style={style}>
            <img id="DPad" src={DPadBackground} alt=""></img>
            <img id="JoyStick" src={DPadJoyStick} style={JSStyle} alt=""></img>
        </div>
    );
}

export default DPad;