import React from "react";
import '../../../styles/Misc/player.css'

import rightIdle from '../../../assets/Player/Idle_Right.png'
import rightRunning_1 from '../../../assets/Player/Running_Right_1.png'
import rightRunning_2 from '../../../assets/Player/Running_Right_2.png'
import leftIdle from '../../../assets/Player/Idle_Left.png'
import leftRunning_1 from '../../../assets/Player/Running_Left_1.png'
import leftRunning_2 from '../../../assets/Player/Running_Left_2.png'
import jumpRightAnim from '../../../assets/Player/Running_Right_1.png'
import jumpLeftAnim from '../../../assets/Player/Running_Left_1.png'

function getPlayerImg(playerImgNum) {
    switch(playerImgNum) {
        case 1:
            return rightRunning_1
        case 2: 
            return rightIdle
        case 3: 
            return rightRunning_2
        case 4:
            return leftRunning_1
        case 5: 
            return leftIdle
        case 6: 
            return leftRunning_2
        case 7:
            return jumpRightAnim
        case 8:
            return jumpLeftAnim
        default: 
            return rightIdle
    }
}


function Player({playerX, playerY, playerImgNum, name}) {

    let currImg = getPlayerImg(playerImgNum)

    const style = {
        left: playerX,
        bottom: playerY
    }

    return ( 
        <img id={name} style={style} src={currImg} alt=""></img>
    );
}

export default Player;