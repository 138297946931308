export const initState = {
    playerX: 30,
    playerY: 100,
    playerLeft: false,
    playerRight: false,
    gravity: false,
    isJumping: false,
    prevPlayerImgNum: 1,
    playerImgNum: 2,
    deviceIsDesktop: true,
    currentWindowLeftX: 0,
    currentScroll: 0,
    dPad: {bool: false, x: 0, y: 0},
    iteration: 0,
    location: 'Outside',
    playerId: 'playerOutside',
    prevX: 30,
    prevWindowX: 0,
};
export function reducer(state, action) {
    let newPlayerX = state.playerX  
    let newPlayerY = state.playerY  
    let newPlayerLeft = state.playerLeft 
    let newPlayerRight = state.playerRight 
    let newGravity = state.gravity  
    let newIsJumping = state.isJumping
    let newPrevPlayerImgNum = state.prevPlayerImgNum 
    let newPlayerImgNum = state.playerImgNum 
    let newDeviceIsDesktop = state.deviceIsDesktop 
    let newCurrentWindowLeftX = state.currentWindowLeftX 
    let newCurrentScroll = state.currentScroll 
    let newDPad = state.dPad 
    let newIteration = state.iteration 
    let newLocation = state.location
    let newPlayerId = state.playerId
    let newPrevX = state.prevX
    let newPrevWindowX = state.prevWindowX


    let playerWidth = document.getElementById(newPlayerId).offsetWidth;
    
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;
    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 

    switch(action.type) {
        case 'setMoveRight':
          newPlayerLeft = false
          newPlayerRight = true
          // newPlayerImgNum = 0
          break
        case 'moveRight':
          if (newLocation === 'Outside') {
            
            let moveSpeedRight = (newDeviceIsDesktop ? 3 : 5) * ratio
            if( (newPlayerX - 30) > (screenWidth * .5) + newCurrentWindowLeftX && newCurrentWindowLeftX < 4369 * ratio - screenWidth) {
              newCurrentWindowLeftX = newCurrentWindowLeftX + moveSpeedRight > 4369 * ratio - screenWidth ? 4369 * ratio - screenWidth : newCurrentWindowLeftX + moveSpeedRight
              newCurrentScroll = newCurrentScroll + moveSpeedRight > 4369 * ratio - screenWidth ? 4369 * ratio - screenWidth : newCurrentScroll + moveSpeedRight
              action.func(newCurrentWindowLeftX)
              window.scrollTo(newCurrentWindowLeftX, 0)
            }
            if (state.playerX < 4250 * ratio - 2.5 - playerWidth) {
              newPlayerX += moveSpeedRight
            }
          } else {
            let moveSpeedRight = (newDeviceIsDesktop ? 5 : 7) * ratio
            let imageRatio = (1536 / 1024)
            let imageWidth = screenHeight * imageRatio
            if (state.playerX < ( screenWidth - ((screenWidth - imageWidth) / 2) ) - 5 - playerWidth) {
              newPlayerX += moveSpeedRight
            }
          }
          newIteration += 1
          break
        case 'stopMoveRight':
          newPlayerRight = false
          newPlayerImgNum = 2
          newIteration = 0
          break
        case 'setMoveLeft':
          newPlayerLeft = true
          newPlayerRight = false
          // newPlayerImgNum = 5
          break
        case 'moveLeft':
          
          if (newLocation === 'Outside') {
            let moveSpeedLeft = (newDeviceIsDesktop ? 3 : 5) * ratio
            if ( (newPlayerX) < (screenWidth * .5) + newCurrentWindowLeftX - playerWidth && newCurrentWindowLeftX >= 0) {
              newCurrentWindowLeftX = newCurrentWindowLeftX - moveSpeedLeft < 0 ? 0 : newCurrentWindowLeftX - moveSpeedLeft
              action.func(newCurrentWindowLeftX)
              window.scrollTo(newCurrentWindowLeftX, 0)
            }
            if (state.playerX >= moveSpeedLeft) {
              newPlayerX -= moveSpeedLeft
            }
          } else {
            let moveSpeedLeft = (newDeviceIsDesktop ? 5 : 7) * ratio
            let imageRatio = (1536 / 1024)
            let imageWidth = screenHeight * imageRatio
            if (state.playerX >= (screenWidth - imageWidth) / 2) {
              newPlayerX -= moveSpeedLeft
            }
          }

          newIteration += 1
          break
        case 'stopMoveLeft':
          newPlayerLeft = false
          newPlayerImgNum = 5
          newIteration = 0
          break
        case 'gravityOff':
          newGravity = true
          break
        case 'gravityOn':
          newGravity = true
          break
        case 'Fall':
          newPlayerY -= (4 * ratio)
          newGravity = true
          break
        case 'setYTurnOffGravity':
          newPlayerY = action.value
          newGravity = false
          break
        case 'setY':
          newPlayerY += action.value
          newGravity = false
          newIsJumping = true
          break
        case 'turnOffJump':
          newIsJumping = false
          break
        case 'updateImage':
          newPrevPlayerImgNum = newPlayerImgNum
          newPlayerImgNum = action.value
          break
        case 'switchToMobile':
          newDeviceIsDesktop = false
          break
        case 'updateDPad':
            if (action.bool != null) {newDPad.bool = action.bool}
            if (action.x) {newDPad.x = action.x}
            if (action.y) {newDPad.y = action.y}     
            break
        case 'setScrollZero':
          newCurrentWindowLeftX = 0
          break
        case 'changeLocation':
          let prevLocation = newLocation
          newLocation = action.location 
          let imageRatio = (1536 / 1024)
          let imageWidth = screenHeight * imageRatio
          // Currently outside
          if (prevLocation === 'Outside') {
            newPrevX = newPlayerX
            newPrevWindowX = newCurrentWindowLeftX
            newCurrentWindowLeftX = ((screenWidth - imageWidth) / 2)
          }
          newPlayerX = ( (screenWidth - imageWidth) / 2) + (200 * screenHeight / 960)
          // Currently in a location
          
          if (newLocation === 'Outside') {
            newPlayerX = newPrevX
            newCurrentWindowLeftX = newPrevWindowX
            window.scrollTo(newCurrentWindowLeftX, 0)
          } 

          newPlayerId = 'player' + newLocation
          break
        default:
          throw new Error('Expected valid action type')
    }
    return {
      playerX: newPlayerX,
      playerY: newPlayerY,
      playerLeft: newPlayerLeft,
      playerRight: newPlayerRight,
      gravity: newGravity,
      isJumping: newIsJumping,
      prevPlayerImgNum: newPrevPlayerImgNum,
      playerImgNum: newPlayerImgNum,
      deviceIsDesktop: newDeviceIsDesktop,
      currentWindowLeftX: newCurrentWindowLeftX,
      dPad: newDPad,
      iteration: newIteration,
      location: newLocation,
      playerId: newPlayerId,
      prevX: newPrevX,
      prevWindowX: newPrevWindowX 
    }
}