import React from "react";

import '../../../styles/Misc/bigStructure.css'
import boatImg from '../../../assets/Misc/Boat final.png'
import houseIMG from '../../../assets/House/House FINAL (resized).png'

function getImageSource(imgType) {
    switch (imgType) {
        case 'boat':
            return boatImg
        case 'house':
            return houseIMG
        default:
            return null
    }
}

function BigStructure({name, imgType, x, y}) {
    
    let imageSource = getImageSource(imgType) 

    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 
    let style = {
        left: x * ratio,
        bottom: y * ratio
    }

    return (
        <img id={name} style={style} src={imageSource} alt=""></img>
    );
}

export default BigStructure;