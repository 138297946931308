import React, { useEffect, useRef, useState } from "react";
import Year from './Year'
import Event from './Event'
import timeline from './timeline.json'
import Navigation from '../Navigation'
import '../styles/timeline.css'

function TimeLine({isMobile}) {
  const eventElementRef = useRef(document.getElementById("TimelineOfEvents"))
  const timeLineHeight = useRef(0) 
  const [timeLinePointerPostition, setPointerPosition] = useState(0)

  let YearComponents = []
  let EventComponents = []
  let eventCount = 0
  let rem = 14
  let inActiveWidth = (2 * rem) + (2.4 * rem) + 4

  for (const year in timeline){
    if(timeline.hasOwnProperty(year)){
      let currYear = timeline[year]
      for (let month in currYear['Months']) {
        let currMonthsEvents = currYear['Months'][month]['Events']
        for (let event of currMonthsEvents) {
          EventComponents.push(
            <Event 
              key={event["Key"]} 
              idx={eventCount}
              month={month} 
              day={event["Day"]} 
              year={year} 
              Title={event["Title"]} 
              smallTitle={event["SmallTitle"]}
              Description={event["Description"]}
              isMobile={isMobile}
            />
          )
          eventCount++
        }
      }
    }
  }


  const onScroll = () => {
    let offsetHeight = eventElementRef.current.scrollTop 
    let scrollHeight = eventElementRef.current.scrollHeight - eventElementRef.current.offsetHeight
    let progression = offsetHeight / scrollHeight
    setPointerPosition(timeLineHeight.current * progression)
  }

  const onTouch = (event) => {
    if (event.changedTouches){
      let touches = event.changedTouches
      for (let Touch of touches) {
        let Y = Touch.pageY
        console.log(Y)
        // 5 Rem of margin on top.
        setPointerPosition(Y - 5*14)
      }
    }
  }

  useEffect(() => {
    let eventElement = document.getElementById("TimelineOfEvents")
    eventElementRef.current = eventElement
    let timeLineElement = document.getElementById("TimeLine")
    // timeLineHeight.current = timeLineElement.offsetHeight
    eventElement.addEventListener('scroll', onScroll)

    // timeLineElement.addEventListener('touchstart', onTouch)
    // timeLineElement.addEventListener('touchmove', onTouch)
    
  }, [])


  useEffect(() => {
    // eventElementRef.current.scrollTop = timeLinePointerPostition
  }, [timeLinePointerPostition])

  return (
    <div className="static-timeline" id="ScrollingTimeline">
      <div className="static-timeline-events" id="TimelineOfEvents">
        {EventComponents}
      </div>
    </div>
  );
}

export default TimeLine;
