import React from "react";
import blackX from '../../../../assets/PageImages/X-black.png'
import '../../../../styles/Popups/skills.css'

function Skills({removePopUp}) {
  return (
    <div className="skills">
      <h1 className="skills-title">SKILLS</h1>
      <img className="skills-x" src={blackX} onClick={() => removePopUp()} alt=""></img>  
      <div className="skills-info info-div">
        <div className="skills-row1">
          <div className="skills-leftside skills-div">
            <h1>Proficient:</h1>
            <ul>
              <li>React</li>
              <li>Vue 2</li>
              <li>Javascript</li>
              <li>AWS</li>
              <li>Python</li>
              <li>SQL</li>
              <li>C</li>
              <li>Java</li>
              <li>Git</li>
            </ul>
          </div>
          <div className="skills-rightside skills-div">
            <h1>Experience With:</h1>
            <ul>
              <li>X86 Assembly</li>
              <li>Matlab</li>
              <li>Salesforce</li>
              <li>C++</li>
              <li>Linux</li>
              <li>Flask</li>
            </ul>
          </div>
        </div>
        <hr className="skills-hr" />
        <div className="skills-row2">
          <h2>--- Looking For ---</h2>
          <p>I am currently looking for a Summer and Fall 2023 Internship in Software/Application Development :)</p>
          <a className="resume" href='Resume/Resume 2024-04-11.pdf' download="Nicholas_Hunt_Resume.pdf">
            <p>Click to download my resume!</p>
          </a>
        </div>
      </div>
      <div className="skills-footer"></div>
    </div>
  );
}

export default Skills;
