import React, {useEffect} from "react";
import Player from '../../Components/Player'
import Book from '../../Components/Book'
import basementIMG from '../../../../assets/House/Basement.png'
import initBasementBooks from './books.json'

import '../../../../styles/House/basement.css'

function Basement({locationRef, setBookPositions, booksFound, state}) {
    
    useEffect( () => {
        
        setBookPositions(initBasementBooks)
        locationRef.current.prevLocation = 'Outside'
        locationRef.current.currLocation = 'Basement'
    }, [])

    return (
        <div id="Basement">
            <img id="BasementIMG" src={basementIMG} alt=""></img>
            {!booksFound.sTan && <Book name={"Studies"} imgType={"Studies"} />}
            <Player name="playerBasement" playerX={state.playerX} playerY={state.playerY} playerImgNum={state.playerImgNum}/>
            <h3 id="leaveInterior">Press R to exit Nick's Basement</h3>
        </div>
    );
}

export default Basement;