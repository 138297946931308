import React, {useEffect, useState} from "react";
import {motion} from 'framer-motion'
import '../styles/contact.css'


import { Email } from './Email';
import { render } from '@react-email/render';

import SuccessIMG from "../../../assets/Misc/Success.png";
import FailureIMG from "../../../assets/Misc/Failure.png";


const sendMail = async (fromAddress, name, subject, body, setSentSuccessfully, setCurrentEmail, setCurrentSubject, setCurrentBody, setCurrentName) => {

  const html = render(
    <Email fromAddress={fromAddress} name={name} bodyText={body} />, {
    pretty: true,
  });

  const requestBody = {
    subject: subject,
    html: html,
  }

  fetch("https://ux6xcxq6c9.execute-api.us-east-1.amazonaws.com/emailHandler", {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-type": "application/json"
    }
  }).then((resp) => {
    console.log(resp)
    if (resp.status !== 200) {
      console.error("Failed to send email. Please try again later.");
      setSentSuccessfully(0);
      return;
    } 
    setSentSuccessfully(1);
    setCurrentEmail("")
    setCurrentName("")
    setCurrentSubject("")
    setCurrentBody("")
  })
  .catch((error) => {
    console.error(error)
    setSentSuccessfully(0);
  })
}

const contactRow = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};


function Contact({isMobile}) {
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentSubject, setCurrentSubject] = useState("");
  const [currentBody, setCurrentBody] = useState("");
  const [sentSuccessfully, setSentSuccessfully] = useState(2)

  const onSubmit = () => {
    sendMail(
      currentEmail, 
      currentName, 
      currentSubject, 
      currentBody, 
      setSentSuccessfully, 
      setCurrentEmail, 
      setCurrentSubject, 
      setCurrentBody, 
      setCurrentName
    )
  }

  useEffect(() => {
    if (sentSuccessfully === 1) {
      setTimeout(() => setSentSuccessfully(2), 4000)
    } 
  }, [sentSuccessfully])
  

  return (
    <>
      <div className="status-flag">
        <img src={sentSuccessfully === 1 ? SuccessIMG : FailureIMG} style={{display: sentSuccessfully !== 2 ? "block" : "none"}} alt="Status Flag"/>
      </div>
      <div className="static-contact">
        <div className="static-contact-form">
          <motion.div 
            variants={contactRow}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="static-contact-form-row-1"
          >
            <div className="static-contact-email static-contact-row">
              {
                !isMobile && 
                <p className="noselect">Who are you?</p> 
              }
              <div className="static-contact-email-input">
                <input type="Email" value={currentEmail} placeholder={"example@email.com"} onChange={(event) => setCurrentEmail(event.target.value)}></input>
                <input type="Name" value={currentName} placeholder={"Edward Cullen"} onChange={(event) => setCurrentName(event.target.value)}></input>
              </div>
            </div>
          </motion.div>
          <motion.div 
            variants={contactRow}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="static-contact-form-row-2"
          >
            <div className="static-contact-subject static-contact-row">
              {
                !isMobile && 
                <p className="noselect">Whats on your mind?</p> 
              }
              <input type="text" value={currentSubject} placeholder={"Little Subject or Header"} onChange={(event) => setCurrentSubject(event.target.value)}></input>
            </div>
          </motion.div>
          <motion.div 
            variants={contactRow}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="static-contact-form-row-3"
          >
            <div className="static-contact-body static-contact-row">
              {
                !isMobile && 
                <p className="noselect">Tell me more :)</p> 
              }
              <textarea rows="10" cols="50" value={currentBody} placeholder={"Long, in-depth thought from the inner mechanisms of your mind."} onChange={(event) => setCurrentBody(event.target.value)}></textarea>
            </div>
          </motion.div>
          <motion.div 
            variants={contactRow}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="static-contact-form-row-4"
          >
            <div onClick={onSubmit} className={"static-button"}>
              <div className="static-button-container">
                <p className="noselect">Submit</p>
              </div>
            </div>
          </motion.div>
          
        </div>
      </div>
    </>
  );
}

export default Contact;
