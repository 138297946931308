import React from "react";
import S_tan from '../../../assets/Books/Book flat S (tan).png'
import P from '../../../assets/Books/Book flat P.png'
import I from '../../../assets/Books/Book flat I.png'
import C from '../../../assets/Books/Book flat C.png'
import E from '../../../assets/Books/Book flat E.png'
import S_orange from '../../../assets/Books/Book flat S (orange).png'
import KeyBasement from '../../../assets/House/Key PNG.png'


import '../../../styles/Popups/flatbooks.css'

function FlatBooks({x, booksFound, showPopUpPage}) {

    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 

    let style = {
        "position": "absolute",
        "left": x,
        "top": 12,
    }
    
    let foundStyle = {
        "cursor": "pointer",
        "opacity": "100%"
    }

    let bookStyle = {
        "opacity": "50%"
    }
    return (
        <div id={"flatBooksDiv"} style={style}>
            <img id="SFlat_tan" className={"flatBooks"} src={S_tan} alt="" style={booksFound.sTan ? foundStyle : bookStyle} onClick={() => booksFound.sTan ? showPopUpPage('sTan') : null}></img>
            <img id="PFlat" className={"flatBooks"} src={P} alt="" style={booksFound.p ? foundStyle : bookStyle} onClick={() => booksFound.p ? showPopUpPage('p') : null}></img>
            <img id="IFlat" className={"flatBooks"} src={I} alt="" style={booksFound.i ? foundStyle : bookStyle} onClick={() => booksFound.i ? showPopUpPage('i') : null}></img>
            <img id="CFlat" className={"flatBooks"} src={C} alt="" style={booksFound.c ? foundStyle : bookStyle} onClick={() => booksFound.c ? showPopUpPage('c') : null}></img>
            <img id="EFlat" className={"flatBooks"} src={E} alt="" style={booksFound.e ? foundStyle : bookStyle} onClick={() => booksFound.e ? showPopUpPage('e') : null}></img>
            <img id="SFlat_orange" className={"flatBooks"} src={S_orange} alt="" style={booksFound.sOrange ? foundStyle : bookStyle} onClick={() => booksFound.sOrange ? showPopUpPage('sOrange') : null}></img>
            <img id="KeyBasementFlat" className={"key"} src={KeyBasement} alt="" style={booksFound.basementKey ? foundStyle : bookStyle}></img>
        </div>
    );
}

export default FlatBooks;