import React from "react";
import blackX from '../../../../assets/PageImages/X-black.png'

import '../../../../styles/Popups/projects.css'

function Projects({removePopUp}) {
  return (
    <div className="projects">
      <h1 className="project-title">Projects</h1>
      <img className="project-x" src={blackX} onClick={() => removePopUp()} alt=""></img>
      <div className="project-info info-div">
        <div className="packJet project">
          <div className="packJet-header project-header">
            <h2 className="packJet-title">PackJet RideJoy</h2>
            <h3 className="packJet-title-desc">, Academic Project Turned Personal</h3>
          </div>
          <ul className="packJet-content project-content">
            <li><p>Developed the popular mobile game Jetpack Joyride using object-oriented programming with a team of four other dev</p></li>
            <li><p>Allocated time to teach three members of the team JavaFX since the three members did not have prior experience.</p></li>
            <li><p>Committed 167 out of 362 contributions to GitHub, accounting for 46% of the total product, leading in total contributions.</p></li>
            <li><p>Formulated an algorithm for the enemy spawn that better handled the enemy quantity, type, and spawn location; this also brought the frame rate of the game up by about double.</p></li>
            <li><p>Communicated daily through a Daily Scrum directed the team on what tasks needed to accomplish that day.</p></li>
            <li><p>Presented the Project at the end of the term and achieved the highest grade in the class, a 95%.</p></li>
          </ul>
        </div>
        <hr className="new-project-hr"/>
        <div className="ECS project">
          <div className="project-header">
            <h2 className="ECS-title">Elements of Computer Systems</h2> 
            <h3 className="ECS-title-desc">, Academic Project</h3>
          </div>
          <ul className="ECS-content project-content">
            <li><p>Read through The Elements of Computer Systems, a book by Noam Nisan and Shimon Schocken within thirteen weeks</p></li>
            <li><p>Completed all twelve projects, took notes, and wrote a final report.</p></li>
            <li><p>Constructed a 16-bit CPU via HDL starting from the Nand logic gate.</p></li>
            <li><p>Built an Assembler for a 16-bit assembly language called Hack. </p></li>
            <li><p>Created a high-level language and developed a machine (push down automata) to recognize the languages syntax</p></li>
            <li><p>Dove into compiler and virtual machine (VM) design (much like JVM)</p></li>
            <li><p>Collaborated with professors to present this as a possible graduate level CS course at RIT within single system design.</p></li>
          </ul>
        </div>
      </div>
      <div className="projects-footer"></div>
    </div>
  );
}

export default Projects;
