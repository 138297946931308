import React, {useEffect, useState} from "react";
import InteriorIMG from '../../../../assets/House/House Interior FINAL.png'
import initInteriorBooks from './books.json'
import Player from '../../Components/Player'
import Book from '../../Components/Book'
import '../../../../styles/House/interior.css'

function Interior({locationRef, setBookPositions, booksFound, state}) {

    useEffect( () => {
        setBookPositions(initInteriorBooks)
        locationRef.current.prevLocation = 'Outside'
        locationRef.current.currLocation = 'Interior'
        
    }, [])

    return (
        <div id="Interior">
            <img id="InteriorIMG" src={InteriorIMG} alt=""></img>
            {!booksFound.p && <Book name={"projects"} imgType={"Projects"}/>}
            <Player name="playerInterior" playerX={state.playerX} playerY={state.playerY} playerImgNum={state.playerImgNum}/>
            <h3 id="leaveInterior">Press R to exit Nick's house</h3>
        </div>
    );
}

export default Interior;