import React from "react";
import musicIMG from '../../../../assets/PageImages/music.jpg'
import climbingIMG from '../../../../assets/PageImages/climbing.jpg'
import blackX from '../../../../assets/PageImages/X-black.png'


import '../../../../styles/Popups/interests.css'

function Interests({removePopUp}) {
  return (
    <div className="interests">
      <h1 className="interest-title">Interests</h1>
      <img className="interest-x" src={blackX} onClick={() => removePopUp()} alt=""></img>
      <div className="interest-info info-div">
        <div className="interest-row1 interest-rows">
          <div className="interest-col1">
            <div className="music-img">
              <img src={musicIMG} alt=""></img>
            </div>
            <div className="music-desc" >
              <h2>Music</h2>
              <p>
                I started playing bass guitar when I was thirteen. 
                Soon after I started, I was thrown into a classic rock band and six months after that, a jazz band.
                I have loved playing out ever since. Jazz has always been my preference but Tom Sawyer by Rush gives you 
                a good kick as well! My freshman year of high school I joined the A capella group at my school and that
                started my singing career. I joined choir the following year and started participating in the Massachusetts 
                district choir every year. My last year I was awarded a solo that I performed along with the choir for an 
                audience at the University of Massachusetts Amherst auditorium. 
              </p>
            </div>
          </div>
          <div className="interest-col2">
            <div className="fitness-desc">
              <h2>Fitness</h2>
              <p>
                Fitness became a huge part of my life when I was a junior in high school. I started working out more frequently. 
                I started my fitness journey weighing 135 lbs 6'1" and I couldn't even bench 45lbs! I now take my physical health very 
                seriously, focusing on my diet and making sure to fit the gym into my semester schedule. I started rock climbing when 
                I went to RIT. I took a rock climbing wellness course my first semester there and met a lot of amazing people!  
              </p>
            </div>
            <div className="fitness-img">
              <img src={climbingIMG} alt=""></img>
            </div>
          </div>
        </div>
        <div className="interest-row2 interest-rows">
          <div className="coding-desc">
            <h2>Coding</h2>
            <p>
              As you can see from my website, coding is a passion of mine. I started coding my freshman year of college; you'll have to 
              find the tan S book to find out when that was ;). I have a fascination of how things (computers in particular) work. 
              That's why I am taking a lot of courses in low level language design and computer Architecture. Prior to that I couldn't 
              even name a single programming language, and now I have written my own compiler and VM in Java! That's the beauty of learning 
              how to code! You don't need any prior knowledge!
            </p>
          </div>
        </div>
      </div>
      <div className="interest-footer"></div>
    </div>
  );
}

export default Interests;
