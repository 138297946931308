import React from "react";
import '../../../styles/Popups/prompt.css'

function OpenBook({x, displayText}) {
    
    let style = {
        "left": "calc(35% + " + x + "px)"
    }

    return (
        <div id="promptDiv" style={style}>
            <div id="promptDivChild">
                <h1>{displayText}</h1>
            </div>
        </div>
    );
}

export default OpenBook;