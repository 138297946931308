import React, {useState} from 'react';
import { motion } from 'framer-motion'
import GameWebsite from './Pages/Game/Index/ControlCenter';
import StaticWebsite from './Pages/Static/Index/StaticWebsite'
import { CookiesProvider } from 'react-cookie';
import './App.css';

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
};

const PageButton = ({showGame, setShowGame}) => {

  const toggleSwitch = () => setShowGame(!showGame);

  return (
    <div className="switch page-switch-button" data-ison={showGame} onClick={toggleSwitch}>
      <motion.div className="handle" layout transition={spring} />
    </div>
  );
}

function App() {
  const [showGame, setShowGame] = useState(false)

  window.oncontextmenu = function(e) {
     e.stopPropagation();
     e.preventDefault();
    return;
  };

  return (
    <CookiesProvider>
      <div className="App">
        <PageButton showGame={showGame} setShowGame={setShowGame} />
        <div id="HomeParent ">
          {showGame && <GameWebsite />}
          {!showGame && <StaticWebsite />}
        </div>
      </div>
    </CookiesProvider>
  );
}

export default App;
