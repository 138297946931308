import React, {useState, useEffect} from "react";
import apiSuccess from '../../../../assets/Misc/Success.png'
import apiFailure from '../../../../assets/Misc/Failure.png'
import blackX from '../../../../assets/PageImages/X-black.png'
import {email} from '../../../../api/Contact'
import '../../../../styles/Popups/contact.css'


const createNewEmail = async (FromAddress, Subject, Body, setSentSuccessfully, setCurrentEmail, setCurrentSubject, setCurrentBody) => {
  
  let s3BodyJson = {
    Subject: Subject,
    Body: Body,
    FromAddress: FromAddress,
  }

  let resp = await email(s3BodyJson)
  if (resp.status === 200) {
    setCurrentEmail("")
    setCurrentSubject("")
    setCurrentBody("")
    setSentSuccessfully(1)
  } else if (resp.status !== 429){
    setSentSuccessfully(0)
  }
};

function Contact({removePopUp}) {
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentSubject, setCurrentSubject] = useState("");
  const [currentBody, setCurrentBody] = useState("");
  const [sentSuccessfully, setSentSuccessfully] = useState(2)

  const onSubmit = () => {
    createNewEmail(currentEmail, currentSubject, currentBody, setSentSuccessfully, setCurrentEmail, setCurrentSubject, setCurrentBody)
  }

  useEffect(() => {
    if (sentSuccessfully === 1) {

      setCurrentEmail("")
      setCurrentSubject("") 
      setCurrentBody("")
    }
  }, [sentSuccessfully])

  return (
    <div className="contact">
      <h1 className="contact-title">CONTACT</h1>
      <img className="contact-x" src={blackX} onClick={() => removePopUp()} alt=""></img>  
      <div className="contact-info info-div">
        {sentSuccessfully === 1 && <img alt='' src={apiSuccess} className="api-resp-message"></img>}
        {sentSuccessfully === 0 && <img alt='' src={apiFailure} className="api-resp-message"></img>}
        <div className="email-div">
          <label htmlFor="Email" className="email-label">Email:     </label>
          <input type="Email" className="email-input" name="Email" value={currentEmail} onChange={(event) => setCurrentEmail(event.target.value)}/>
        </div>
        <div className="subject-div">
          <label htmlFor="Subject" className="subject-label">Subject:     </label>
          <input type="text" className="subject-input" name="Subject" value={currentSubject} onChange={(event) => setCurrentSubject(event.target.value)}/>
        </div>
        <div className="body-div">
          <label htmlFor="Body" className="body-label">Body:</label>
          <textarea className="body-input" name="Body" rows="30" cols="50" value={currentBody} onChange={(event) => setCurrentBody(event.target.value)}></textarea>
        </div>
        <div className="submit-div">
          <button className="submit-button" onClick={onSubmit}>Submit</button>
        </div>
      </div>
      <div className="contact-footer"></div>
    </div>
  );
}

export default Contact;
