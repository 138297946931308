import React, {useEffect, useState, useRef} from "react";
import '../styles/event.css'

const constructDetailsComponent = (details) => {
  // {} defines new section with a "title": [] || ""
  // [] defines bulleted list
  // "" defines string
  let result = []
  if (typeof details === 'object') {
    let count = 0
    for (let title in details) {
      let ListComponent = []
      count++
      for (let ele of details[title]) {
        if (Array.isArray(ele)) {
          let innerListComponent = []
          for (let ele2 of ele) {
            innerListComponent.push(<li className="static-event-details-li" key={ele2}>{ele2}</li>)
          }  
          ListComponent.push(<ul key={innerListComponent} className="static-event-details-ul">{innerListComponent}</ul>)
        } else {
          ListComponent.push(<li className="static-event-details-li" key={ele}>{ele}</li>)
        }
      }
      result.push(
        <div key={title} className="static-event-details-container" data-is-not-last={count !== Object.keys(details).length}>
          <h5 className="static-event-details-title">{title}</h5>
          <ul className="static-event-details-ul">
            {ListComponent}
          </ul>
        </div>
      )
    }  
  } else if (typeof details === 'string') {
    result = <p>{details}</p>
  } else {
    return null
  }

  return result
}


function Event({idx, month, day, year, Title, smallTitle, Description, isMobile}) {
  const [isActive, setIsActive] = useState(false)
  const activeRef = useRef(false)
  const [showNewTitle, setShowNewTitle] = useState(false)
  const hasDesc = Description !== ""
  let midPoint = 0
  let rem = 14
  let inActiveWidth = (2 * rem) + (2.4 * rem) + 4
  let activeWidth = (21.5 * rem) + (2.4 * rem) + 4

  const activefunc = hasDesc ? () => {activeRef.current = !isActive ;setIsActive(!isActive);} : () => false

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setShowNewTitle(true)
      }, 300)
    } else {
      setShowNewTitle(false)
    }
  }, [isActive])

  const onClickEvent = (event) => {
    let ele = document.getElementById('ScrollingTimeline')
    let eventElement = document.getElementsByClassName('static-event')[idx]

    if (!midPoint) {
      let eleWidth = getComputedStyle(ele).width
      console.log(eleWidth.substring(0, eleWidth.length - 2))
      midPoint = Number.parseInt(eleWidth.substring(0, eleWidth.length - 2)) / 2
    }
    let xValue = eventElement.offsetLeft + (activeWidth / 2) - (midPoint)
    console.log(eventElement.offsetLeft)
    console.log(activeWidth)
    console.log(midPoint)
    
    setTimeout(() => {
      console.log(activeRef.current)
      if (activeRef.current)
        ele.scrollTo({left: xValue, behavior: 'smooth'})
    }, 300)
    console.log(xValue)
  } 

  return (
    <div className="static-event" onClick={isMobile ? () => {} : onClickEvent}>
      <div className="static-event-container" onClick={() => activefunc()} data-is-active={isActive} style={{"cursor": hasDesc ? "pointer" : "", 'backgroundColor': hasDesc ? 'var(--Sage)' : 'var(--Dark-Sage)'}}>
        <div className="static-event-title-container noselect">
          <h5 data-is-active={isActive} /*style={{width: isActive ? 'fit-content' : 'max-content', height: isActive ? '' : '1rem'}}*/>
            {Title}
          </h5>
          <h5>{month}-{day === "" ? "1" : day}-{year}</h5>          
        </div>
        {/* {
          isActive && Description !== "" &&  */}
          <div className="static-event-description-container" style={hasDesc ? {} : {"display": "None"}}>
            {constructDetailsComponent(Description)}
          </div>
        {/* } */}
      </div>
    </div>
  );
}

export default Event;
