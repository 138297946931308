import React from "react";
import blackX from '../../../../assets/PageImages/X-black.png'
import VoyaIMG from '../../../../assets/PageImages/Voya_Financial_logo.png'
import ShipIMG from '../../../../assets/PageImages/Vanguard-emblem.png'
import VanguardIMG from '../../../../assets/PageImages/Vanguard-logo.png'

import '../../../../styles/Popups/experience.css'

function Experience({removePopUp}) {
  return (
    <div className="experience">
      <h1 className="experience-title">EXPERIENCE</h1>
      <img className="experience-x" src={blackX} onClick={() => removePopUp()} alt=""></img>  
      <div className="experience-info info-div">
        <div className="experience-row1 experience-row">
          <div className="vanguard-col1">
            <div className="logo-div"><img className="logo-img" src={VanguardIMG} alt=""></img></div>
          </div>
          <div className="vanguard-col2">
            <h3>The Vanguard Group</h3>
            <p>Application Development Intern</p>
            <ul>
              <li>Works with a team of full stack engineers to develop internal business applications for Vanguard analysts, quants, traders, and portfolio managers. </li>
              <li>Designed a production level microservice using AWS lambda and IAM roles to sync up prod and test data.</li>
              <li>Introduced and implemented a utility module for common functionality within our micro-UI to reduce duplicate code.</li>
              <li>Used Webpack/single spa to export/import widgets from our application to a parent dashboard application.</li>
              <li>Uses AWS resources, Vue JS, Python, and PostgreSQL to create a web app for use within analytics and quick decisive trading.</li>
            </ul>
          </div>
        </div>
        <hr className="experience-hr"/>
        <div className="experience-row2 experience-row">
          <div className="voya-col1">
            <h3 className="voya-title">Voya Financial</h3>
            <p>Software Engineer Intern</p>
            <ul>
              <li>Worked with a team to develop customized solutions within the Salesforce Platform.</li>
              <li>Uploaded and activated 208 user accounts within our newly developed Salesforce Org.</li>
              <li>Completed 50% of the Salesforce Developer Training courses on Trailheads.</li>
            </ul>
          </div>
          <div className="voya-col2">
            <img className="voya-img" src={VoyaIMG} alt=""></img>
          </div>
        </div>
        <hr className="experience-hr"/>
        <div className="experience-row3">
          <h1 className="additional-exp">Additional Experience</h1>
        </div>
        <div className="experience-row4 experience-row">
          <div className="additional-header">
            <h3 className="rit-title">Rochester Institute of Technology, </h3>
            <p>RIT Service Center Representative</p>
          </div>
          <ul>
            <li>Applies knowledge of hardware, software, systems, networks, and printers to guide customers through technical problems.</li>
            <li>Helps students, teachers, and faculty resolve issues concerning their personal and RIT owned devices, and RIT accounts.</li>
          </ul>
        </div>
        <div className="experience-row5 experience-row">
          <div className="additional-header">
            <h3 className="rice-title">Rice Fruit Farm, </h3>
            <p>Line Cook</p>
          </div>
          <ul>
            <li>Completed tasks such as cooking food, preparing tomorrows ingredients, cleaning up after shift, and serving customers.</li>
            <li>Worked on the weekends with a team of roughly four other cooks to process a total of around 600 orders within six hours.</li>
          </ul>
        </div>
      </div>
      <div className="experience-footer"></div>
    </div>
  );
}

export default Experience;
