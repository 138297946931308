import React from "react";
import Outside from '../Outside/Outside'
import Basement from '../House/Basement/Basement'
import Interior from '../House/Interior/Interior'

import '../../../styles/Misc/location.css'

function Location({locType, setBookPositions, setPlatforms, state, booksFound, locationRef}) {
    return (
        <>
            {locType === "Basement" && <Basement locationRef={locationRef} setBookPositions={setBookPositions} setPlatforms={setPlatforms} state={state} booksFound={booksFound}/>}
            {locType === "Interior" && <Interior locationRef={locationRef} setBookPositions={setBookPositions} setPlatforms={setPlatforms} state={state} booksFound={booksFound}/>}
            {locType === "Outside"  && <Outside locationRef={locationRef} setBookPositions={setBookPositions} setPlatforms={setPlatforms} state={state} booksFound={booksFound}/>}
        </>
    );
}

export default Location;