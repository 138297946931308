import React, { useState, useRef } from "react";
import {motion} from 'framer-motion'
import facts from './facts.json'
import '../styles/aboutme.css'

const transDist = 200

const aboutMeRow = {
  hidden1: { x: -1 * transDist, opacity: 0 },
  hidden2: { x: transDist, opacity: 0 },
  exit1: { x: transDist, opacity: 0 },
  exit2: { x: -1 * transDist, opacity: 0 },
  visible: { x: 0, opacity: 1 },
  transition: { duration: 0.8 }
};

function AboutMe() {
  const currentFactIdx = useRef(0)
  const maxFactsIdx = facts.length
  const [currentFact, setCurrentFact] = useState(facts[0])

  const getNewFact = () => {
    currentFactIdx.current++
    if (currentFactIdx.current === maxFactsIdx) {
      currentFactIdx.current = 0 
    }

    setCurrentFact(facts[currentFactIdx.current])
  }

  return (
    <div className="static-aboutme">
      <div className="static-aboutme-container">
        <motion.div 
          className="static-about-me-rows static-about-me-row-1"
          variants={aboutMeRow}
          initial={"hidden1"}          
          exit={"exit1"}
          animate={"visible"}
        >
          <div className="static-about-me-col static-about-me-col-long">
            <h3 className="noselect">
              Things I enjoy
            </h3>
          </div>
        </motion.div>
        {
          currentFactIdx.current % 2 === 1 && 
          <motion.div 
            className="static-about-me-rows static-about-me-row-2"
            variants={aboutMeRow}
            initial={"hidden2"}
            exit={"exit2"}
            animate={"visible"}
            transition={"transition"}
          >
            <div className="static-about-me-col static-about-me-col-small">
              {
                currentFact.Title.map((text, idx) => (
                  <p key={"title=" + text + idx} className="noselect">
                    {text}
                  </p>
                ))
              }
            </div>
            <div className="static-about-me-col static-about-me-col-big">
              <ul className="static-about-me-ul">
                {
                  currentFact.Desc.map((desc, idx) => (
                    <li key={"desc=" + desc + idx} className="noselect">
                      {desc}
                    </li>
                  ))
                } 
              </ul>
            </div>
          </motion.div>
        }
        {
          currentFactIdx.current % 2 === 0 && 
          <motion.div 
            className="static-about-me-rows static-about-me-row-2"
            variants={aboutMeRow}
            initial={"hidden1"}
            exit={"exit1"}
            animate={"visible"}
            transition={"transition"}
          >
            <div className="static-about-me-col static-about-me-col-big">
              <ul className="static-about-me-ul">
                {
                  currentFact.Desc.map((desc, idx) => (
                    <li key={"desc=" + desc + idx} className="noselect">
                      {desc}
                    </li>
                  ))
                } 
              </ul>
            </div>
            <div className="static-about-me-col static-about-me-col-small">
              {
                currentFact.Title.map((text, idx) => (
                  <p key={"title=" + text + idx} className="noselect">
                    {text}
                  </p>
                ))
              }
            </div>
          </motion.div>
        }
        <motion.div 
          variants={aboutMeRow}
          initial={"hidden1"}
          exit={"exit1"}
          animate={"visible"}
          className="static-about-me-rows static-about-me-row-3"
        >
          <div onClick={getNewFact} className={"static-button"}>
            <div className="static-button-container">
              <p className="noselect">Next</p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default AboutMe;
