import React, {useState, useEffect} from "react";
import '../styles/navigation.css'

const Navigation = ({currPage, setCurrPage, isMobile}) => {
    const [drawerOpen, setDrawerOpen] = useState(false)
        
    useEffect(() => {
        setTimeout(() => {
            if (isMobile) {
                let hamburger = document.getElementById("staticNavHamburger")
                let elements = document.getElementsByClassName("static-nav-hamburger-line")
                if (hamburger || elements.length !== 0) {
                    hamburger.setAttribute("data-animate-hamburger", drawerOpen)
                    Array.from(elements).map((element) => element.setAttribute("data-animate-hamburger", drawerOpen))
                }
            }
        }, 300)
    }, [drawerOpen, isMobile])

    return (
        <div className="static-nav" data-drawer-active={drawerOpen} data-current-page={drawerOpen + "-" + currPage}>
            <div className="static-nav-title-container" data-drawer-active={drawerOpen}>
                { isMobile && 
                    <div className="static-nav-hamburger-container">
                        <div className="static-nav-hamburger" id="staticNavHamburger" onClick={() => setDrawerOpen(!drawerOpen)} data-drawer-active={drawerOpen}>
                            <span className="static-nav-hamburger-line static-nav-hamburger-top noselect" data-drawer-active={drawerOpen}></span>
                            <span className="static-nav-hamburger-line static-nav-hamburger-middle noselect" data-drawer-active={drawerOpen}></span>
                            <span className="static-nav-hamburger-line static-nav-hamburger-bottom noselect" data-drawer-active={drawerOpen}></span>
                        </div>
                    </div>
                }
                {/* {
                    ((drawerOpen === true && isMobile) || !isMobile) &&  */}
                <h1 data-is-active={drawerOpen} className="static-nav-title noselect">{isMobile ? 'icholas Hunt' : 'Nicholas Hunt'}</h1>
                {/* } */}
            </div>
            {
                ((drawerOpen === true && isMobile) || !isMobile) && 
                <div className="static-nav-pages">
                    <h3 className="static-nav-page-link noselect"><a href={`/`}>Programming Timeline</a></h3>
                    <div className="static-nav-page-about-me-wrapper">
                        <h3 className="static-nav-page-link noselect"><a href={`/about-me`}>About</a></h3>
                        {/* <div className="static-nav-page-about-me-dropdown">
                            <h5 className="noselect"><a href={`/about-me`}>Me</a></h5>
                            <h5 className="noselect"><a href={`/about-game`}>Game on Desktop</a></h5>
                            <h5 className="noselect" onClick={() => switchPage("AboutGameMobile")}>Game on Mobile</h5>
                        </div> */}
                    </div>
                    <h3 className="static-nav-page-link noselect"><a href={`/contact`}>Contact</a></h3>
                    {/* {   
                        isMobile &&
                        <img className="static-nav-mobile-backarrow" src={leftArrowImg} alt=""></img>
                    } */}
                    <h3 className="static-nav-page-link noselect"><a className="resume" href="/resume">Resume</a></h3>
                </div>
            }
        </div>  
    )
}

export default Navigation