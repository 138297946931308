import React, {useEffect, useState} from "react";
import Outside1 from '../../../assets/Outside/Outside 1.png'
import Outside4 from '../../../assets/Outside/Outside 4.png'
import Platform from '../Components/Platform'
import BigStructure from '../Components/BigStructure'
import Player from '../Components/Player'
import Book from '../Components/Book'
import initOutsideBooks from './books.json'
import initPlatformsOutside from './platform.json'
import Prompt from '../Components/Prompt'
import keyAnim from '../../../assets/House/key_Basement.gif'
import {doorCollisions} from '../../../helper/playerHelper'
import '../../../styles/Outside/outside.css'


function Outside({locationRef, setBookPositions, setPlatforms, booksFound, state}) {
    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 
    const [showInteriorDoorPopUp, setShowInteriorDoorPopUp] = useState(false)
    const [showBasementDoorPopUp, setShowBasementDoorPopUp] = useState(false)

    useEffect( () => {
        setPlatforms(initPlatformsOutside)
        setBookPositions(initOutsideBooks)
    }, [])

    useEffect( () => {

        const width = document.getElementById(state.playerId).offsetWidth
        // eslint-disable-next-line
        window.requestAnimationFrame(() => {
            let interiorCollision = doorCollisions(state.playerX, state.playerY, width, 50 * ratio, 150 * ratio, 1595 * ratio, 200 * ratio)
            let basementCollision = doorCollisions(state.playerX, state.playerY, width, 50 * ratio, 200 * ratio, 1900 * ratio, 200 * ratio)
            
            
            if (interiorCollision && locationRef.current.prevLocation !== 'Interior') {
                locationRef.current.prevLocation = 'Outside'
                locationRef.current.currLocation = 'Interior'
            } else if (basementCollision && locationRef.current.prevLocation !== 'Basement') {
                locationRef.current.prevLocation = 'Outside'
                locationRef.current.currLocation = 'Basement'
            } else {
                locationRef.current.prevLocation = null
                locationRef.current.currLocation = 'Outside'
            }
            setShowBasementDoorPopUp(basementCollision)
            setShowInteriorDoorPopUp(interiorCollision)
        })          
      }, [state.playerX, state.playerY])
      
      let keyAnimX = {
        "left": 1700 * ratio
      }

    return (
        <>
            <div id="OutsideIMG">
                <img id="Outside1" src={Outside1} alt=""></img>
                <img id="Outside2" src={Outside1} alt=""></img>
                <img id="Outside3" src={Outside1} alt=""></img>
                <img id="Outside4" src={Outside1} alt=""></img>
                <img id="Outside5" src={Outside4} alt=""></img>
            </div>
            <Platform  name={"wood1"} class_name={"woodPlatform"} x={300} y={170} imgType={"wood"}/>
            <Platform  name={"wood2"} class_name={"woodPlatform"} x={510} y={300} imgType={"wood"}/>
            <Platform  name={"wood3"} class_name={"woodPlatform"} x={710} y={420} imgType={"wood"}/>
            {!booksFound.i       && <Book      name={"book3"} imgType={"Interests"}  x={900} y={90}/>}
            {!booksFound.c       && <Book      name={"book4"} imgType={"Contact"}    x={1100} y={90}/>}
            {!booksFound.e       && <Book      name={"book5"} imgType={"Experience"} x={2100} y={90}/>}
            {!booksFound.sOrange && <Book      name={"book6"} imgType={"Skills"}     x={2300} y={90}/>}
            <BigStructure imgType={"house"} name="house" x={1000} y={120}/>
            {!booksFound.basementKey && <img id="keyBasement" style={keyAnimX} src={keyAnim} alt=""></img>}
            <Player name="playerOutside" playerX={state.playerX} playerY={state.playerY} playerImgNum={state.playerImgNum}/>
            {showInteriorDoorPopUp && <Prompt x={state.currentWindowLeftX} displayText={"Press E to enter Nick's House"}/>}
            {showBasementDoorPopUp && <Prompt x={state.currentWindowLeftX} displayText={booksFound.basementKey ? "Press E to enter Nick's Basement" : "The Cellar is locked. You need to find a key!!!"}/>}
        </>
    );
}

export default Outside;