import React from "react";

import '../../../styles/Popups/book.css'
import template from '../../../assets/Books/BookTemplate.png'
import S_tan from '../../../assets/Books/Book S (tan).png'
import P from '../../../assets/Books/Book P.png'
import I from '../../../assets/Books/Book I.png'
import C from '../../../assets/Books/Book C.png'
import E from '../../../assets/Books/Book E.png'
import S_orange from '../../../assets/Books/Book S (orange).png'

function getImageSource(imgType) {
    switch (imgType) {
        case 'Studies':
            return S_tan
        case 'Projects':
            return P
        case 'Interests':
            return I
        case 'Contact':
            return C
        case 'Experience':
            return E
        case 'Skills':
            return S_orange
        
        default:
            return template 

        
        // C S E P S I
        //                         < --- SPICES --- > 
        // User has to collects the SPICES to cook a recipe and unlock a feature.


    }
}

function Book({name, imgType, x, y}) {
    
    let imageSource = getImageSource(imgType) 

    var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 
    let style = {}
    if (x && y) {
        style = {
            left: x * ratio,
            bottom: y * ratio
        }
    }

    return (
        <img id={name} className={"Book"} style={style} src={imageSource} alt=""></img>
    );
}


export default Book;