var ratio = window.innerHeight < window.innerWidth ? window.innerHeight / 969 : window.innerWidth / 969 

function getNextRightAnimationFrame(currFrame, prevFrame) {
    if (currFrame > 3) {
        return 2
    }
    if (currFrame === 3 || currFrame === 1) {
        return 2
      } else {
        if (prevFrame === 3) {
          return 1
        } else {
          return 3
        }
      }
}

export function animateRight(state, dispatch, updateWindowX, iteration) {
    if (iteration % 12 === 0 && !state.isJumping) {
        dispatch({type: 'updateImage', value: getNextRightAnimationFrame(state.playerImgNum, state.prevPlayerImgNum)})
    }
    dispatch({type: 'moveRight', func: updateWindowX})
}

function getNextLeftAnimationFrame(currFrame, prevFrame) {
    if (currFrame < 4 || currFrame > 6) {
        return 5
    }
    if (currFrame === 6 || currFrame === 4) {
        return 5
      } else {
        if (prevFrame === 6) {
          return 4
        } else {
          return 6
        }
      }
}

export function animateLeft(state, dispatch, updateWindowX, iteration) {
    if (iteration % 12 === 0 && !state.isJumping) {
        dispatch({type: 'updateImage', value: getNextLeftAnimationFrame(state.playerImgNum, state.prevPlayerImgNum)})
    }
    dispatch({type: 'moveLeft', func: updateWindowX})
}

function getJumpAnim(playerImgNum) {
    if ( playerImgNum < 4 ) {
        return 7
    } else if ( playerImgNum >= 4 && playerImgNum <= 6) {
        return 8

    }
}

export function jump(dispatch, jumpGravityRef, playerImgNum) {
    dispatch({type: 'updateImage', value: getJumpAnim(playerImgNum)})
    dispatch({type: 'setY', value: 0})
    let timeCount = 0
    let timeInc = 20
    let screenHeight = window.screen.height
    for (let i=0; i<6; i++ ) {
        timeCount += timeInc
        setTimeout(() => {jumpGravityRef(true, (screenHeight / 144) * ratio)}, timeCount)
    } // 50 px
    for (let i=0; i<8; i++ ) {
        timeCount += timeInc
        setTimeout(() => {jumpGravityRef(true, (screenHeight / 179) * ratio)}, timeCount)
    } // 48 px
    for (let i=0; i<10; i++ ) {
        timeCount += timeInc
        setTimeout(() => {jumpGravityRef(true, (screenHeight / 288) * ratio)}, timeCount)
    } // 42 px
    for (let i=0; i<14; i++ ) {
        timeCount += timeInc
        setTimeout(() => {jumpGravityRef(true, (screenHeight / 432) * ratio)}, timeCount)
    } //  32px
    
    // Total Pixels: 190px


    setTimeout( () => {dispatch({type: 'turnOffJump'})}, timeCount) 
    // timeCount += timeInc    
    timeCount += 75
    setTimeout(() => {
        dispatch({type: 'gravityOff'})
        jumpGravityRef(false, null)
    }, timeCount)

    

}

export function gravity(state, dispatch) {
    if (state.gravity) {
        dispatch({type: 'Fall'})
        setTimeout(() => {window.requestAnimationFrame(gravity)}, 10)
    } 
}

export function bookCollision(playerX, playerY, playerWidth, bookPositions, booksFound) {
    const delta = 30 * ratio;
    if (!booksFound) return null

    for (let bookYstr in bookPositions) {
        const bookYnum = parseInt(bookYstr) * ratio
        let currYDiff = Math.abs(bookYnum - playerY)
        if (currYDiff <= delta) {
            let currObjList = bookPositions[bookYstr]
            for (let currObj of currObjList) {
                if (!booksFound[currObj.identifier]) {
                    if ( (currObj.middle * ratio - delta) - (playerX + playerWidth) <= 0 && (currObj.middle * ratio + delta) - playerX >= 0) {
                        return currObj.identifier
                    }
                }
            }
        }
    }
    return null
}


export function keyCollision(playerX, playerY, playerWidth, XDelta, YDelta, X, Y) {
    let currYDiff = Math.abs(Y - playerY)
    if (currYDiff <= YDelta) {
        if ( ( X - XDelta) - (playerX + playerWidth) <= 0 && ( X + XDelta) - playerX >= 0) {
            return true
        }
    }
    return false
}

export function doorCollisions(playerX, playerY, playerWidth, XDelta, YDelta, X, Y) {
    let currYDiff = Math.abs(Y - playerY)
    if (currYDiff <= YDelta) {
        if ( ( X - XDelta) - (playerX + playerWidth) <= 0 && ( X + XDelta) - playerX >= 0) {
            return true
        }
    }
    return false
}


export function platformCollision(playerX, playerY, isJumping, playerWidth, dispatch, platforms) {
    const delta = 4 * ratio
    let hasCollision = false
    for (const Ystr in platforms) {
        if (!hasCollision) {
            const Ynum = parseInt(Ystr) * ratio
            let currYDiff = Math.abs(Ynum - playerY)
            if ( currYDiff <= delta || playerY < 120 * ratio) {
                let currObjList = platforms[Ystr]
                for (let currObj of currObjList) {
                    if ((currObj.Left * ratio) - playerX - playerWidth <= delta && (currObj.Right * ratio) - playerX >= delta ) {
                        dispatch({type: 'setYTurnOffGravity', value: Ynum})
                        hasCollision = true
                        break
                    }
                }
            } 
        }
    }
    if (!hasCollision && !isJumping) {
        dispatch( {type: 'gravityOn'} )
    }
}
