import React from "react";
import RITLogo from '../../../../assets/PageImages/RIT_Logo.png'
import WSULogo from '../../../../assets/PageImages/WSU_Logo.png'
import blackX from '../../../../assets/PageImages/X-black.png'



import '../../../../styles/Popups/studies.css'


function Studies({removePopUp}) {
  return (
    <div className="studies">
      <h1 className="studies-title">Studies</h1>
      <img className="studies-x" src={blackX} onClick={() => removePopUp()} alt=""></img>
      <div className="studies-info info-div">
        <div className="studies-leftside college-div">
          <div className="college-logo">
            <img className="WSU-logo" src={WSULogo} alt=""></img>
          </div>
          <div className="WSU-info college-info">
            <h4 className="WSU-years-title titles">Years Attended: </h4>
            <p className="WSU-years-content">Fall 2019 - Spring 2021</p>
            <h4 className="WSU-major-title titles">Major: </h4>
            <p className="WSU-major-content">Computer Science (B.S.) and Mathematics (B.A.)</p>
            <h4 className="WSU-classes-title titles">Classes:</h4>
            <ul className="WSU-classes-content">
              <li>Web Design</li>
              <li>Computer Organization and Architecture</li>
              <li>Calculus III</li>
              <li>Discrete Math with Proofs</li>
            </ul>
            
          </div>
        </div>
        <div className="studies-rightside college-div">
          <div className="college-logo">
            <img className="RIT-logo" src={RITLogo} alt=""></img>
          </div>
          <div className="RIT-info college-info">
            <h4 className="RIT-years-title titles">Years Attended: </h4>
            <p className="RIT-years-content">Fall 2021 - Spring 2024</p>
            <h4 className="RIT-major-title titles">Major: </h4>
            <p className="RIT-major-content">Computer Science (B.S.)  w/ minor in Mathematics</p>
            <h4 className="RIT-classes-title titles">Classes: </h4>
            <ul  className="RIT-classes-content">
              <li>Advanced Linear Algebra</li>
              <li>Numerical Linear Algebra</li>
              <li>Elements of Computer Systems (Independent Study)</li>
              <li>Compiler Construction</li>
            </ul>
            
          </div>
        </div>
      </div>
      <div className="studies-footer"></div>
    </div>
  );
}

export default Studies;
