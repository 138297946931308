import React, {useEffect, useRef, useState} from "react";
import TimeLine from "../TimeLine";
import AboutMe from '../AboutMe'
import Desktop from '../AboutGame/Desktop'
import Mobile from '../AboutGame/Mobile'
import Resume from "../Resume"
import Contact from '../Contact'
import Navigation from '../Navigation'
import { motion } from "framer-motion"
import '../styles/static-website.css'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const contactContainer = {
  hidden: { opacity: 0, scale: 0 },
  exit: {y: -20, opacity: 0},
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.3
    }
  }
};

const aboutMeContainer = {
  hidden: { opacity: 0, scale: 0 },
  exit: { x: -20, opacity: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.3
    }
  }
}


function StaticWebsite() {
  const currXRef = useRef(0)
  const ShiftDown = useRef(false)
  const [isMobile, setIsMobile] = useState(window.outerWidth < 480)
  const [currPage, setCurrPage] = useState("AboutGameMobile")

  const handleScroll = (event) => {
    let staticBody = document.getElementById('staticBody')
    if (ShiftDown.current) {
      currXRef.current = staticBody.scrollX
    }
    if (event.deltaY < 0 && currXRef.current > 0) {
      currXRef.current -= 15
    } else if (event.deltaY > 0) {
      currXRef.current += 15
    }
    staticBody.scrollTo(currXRef.current, 0)
  };  
  const handleKeyDown = (event) => {
    if (event.key === 'Shift') {
      ShiftDown.current = true
    }
  }
  const handleKeyUp = (event) => {
    if (event.key === 'Shift') {
      ShiftDown.current = false
    }
  }

  const updateIsMobile = () => {
    return setIsMobile(window.outerWidth < 480)
  }

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, false)
    window.addEventListener("keydown", handleKeyDown, false)
    window.addEventListener("keyup", handleKeyUp, false)
    window.addEventListener('resize', updateIsMobile, false)
    return () => {
      window.removeEventListener("wheel", handleScroll, false)
      window.removeEventListener("keydown", handleKeyDown, false)
      window.removeEventListener("keyup", handleKeyUp, false)
      window.removeEventListener('resize', updateIsMobile, false)
    };
  }, []);


  const router = createBrowserRouter([
    {
      path: "/",
      element:  <motion.div
                  className="static-timeline-motion static-motion"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <TimeLine />
                </motion.div>,
    },
    {
      path: "/about-me",
      element:  <motion.div
                  className="static-aboutme-motion static-motion"
                  variants={aboutMeContainer}
                  initial={"hidden"}
                  animate={"visible"}
                  exit={"exit"}
                >
                  <AboutMe isMobile={isMobile}/>
                </motion.div>,
    },
    {
      path: "/about-game",
      element:  <motion.div
                  className="static-game-parent"
                >
                  <Desktop />
                </motion.div>,
    },
    {
      path: "/contact",
      element:  <motion.div
                  className="static-contact-motion static-motion"
                  variants={contactContainer}
                  initial={"hidden"}
                  animate={"visible"}
                  exit={"exit"}
                >
                  <Contact isMobile={isMobile}/>
                </motion.div>,
    },
    {
      path: "/resume",
      element: <motion.div
                  className="static-contact-motion static-motion"
                  variants={contactContainer}
                  initial={"hidden"}
                  animate={"visible"}
                  exit={"exit"}
                >
                  <Resume isMobile={isMobile}/>  
                </motion.div>,
    }
  ]);


  return (
    <div className="static" id="staticBody">
      <Navigation isMobile={isMobile} />
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}

export default StaticWebsite;
